/* eslint-disable @typescript-eslint/no-unused-vars */
import { initLogging } from '../config/initBrowserLogging';
// TODO: move this css boilerplate into a next.js plugin
import 'normalize.css'; // css reset
import '@cvent/carina/fonts/fonts.web.css';

import React from 'react';
import { ApolloProvider, InMemoryCache, makeVar } from '@apollo/client';
import { useApollo } from '@cvent/apollo-client/useApollo';
import AppProviders from '@components/AppProviders';
import { HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Logger } from '@cvent/nucleus-logging/lib';
import { initRum } from '@cvent/nextjs/datadog/initRum';
import { ApolloClientFactory, createAuthLink } from '@cvent/apollo-client';
import getConfig from 'next/config';
import Script from 'next/script';
import ErrorBoundary from '@components/errors/ErrorBoundary';
import { handleGraphqlError } from '@datasources/authorization-rules/error-handler';

initLogging();

const { publicRuntimeConfig } = getConfig();

// example of app wide global state, move to another file and import here as this grows!
// to learn more options for local state management go here; https://www.apollographql.com/blog/local-state-management-with-reactive-variables/
export const darkModeVar = makeVar<boolean>(false);
const clientVersion = makeVar<string>(String(publicRuntimeConfig.DD_VERSION) || 'unknown');
const LOG = new Logger('App');

const apolloCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        clientVersion: {
          read(): string {
            return clientVersion();
          }
        },
        darkMode: {
          read(): boolean {
            return darkModeVar();
          }
        }
      }
    },
    Hotel: {
      keyFields: ['hotelId'],
      fields: {
        hotelLocation: {
          keyArgs: ['eventId']
        }
      }
    }
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      LOG.debug(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
    handleGraphqlError({
      graphQLErrors,
      cache: apolloCache
    });
  }
  if (networkError) {
    LOG.debug(`[Network error]: ${networkError}`);
  }
});

const httpLink = new HttpLink({
  uri: '/api/graphql'
});

const createApolloLinks = () => {
  if (publicRuntimeConfig.DEV_LOGIN === 'true') {
    const loginUrl = publicRuntimeConfig.LOGIN_URL?.replace('http://', ''); // strip out the `http://` from the login URL
    return [createAuthLink(loginUrl), errorLink, httpLink];
  }
  return [createAuthLink(), errorLink, httpLink];
};

interface AppProps {
  Component: any;
  pageProps: any;
}

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  const logConfig = {
    applicationId: publicRuntimeConfig.DD_APP_ID,
    clientToken: publicRuntimeConfig.DD_CLIENT_TOKEN,
    service: publicRuntimeConfig.DD_SERVICE,
    env: publicRuntimeConfig.DD_ENV,
    version: publicRuntimeConfig.DD_VERSION,
    logLevel: publicRuntimeConfig.LOG_LEVEL
  };

  initRum(logConfig);
  const client = useApollo(
    pageProps,
    new ApolloClientFactory({
      initialState: {},
      loginUrl: '',
      graphBasePath: '',
      graphUri: ''
    }),
    {
      cache: apolloCache,
      link: from(createApolloLinks()),
      connectToDevTools:
        publicRuntimeConfig.DD_ENV.toLowerCase().includes('dev') || publicRuntimeConfig.DD_ENV.toLowerCase() === 'alpha'
    }
  );

  const getLayout = Component.getLayout || (page => page);

  return (
    <ApolloProvider client={client}>
      <AppProviders>
        {publicRuntimeConfig.GOOGLE_ANALYTICS && (
          <>
            <Script async src={`https://www.googletagmanager.com/gtag/js?id=${publicRuntimeConfig.GOOGLE_ANALYTICS}`} />
            <Script>
              {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
    
              gtag('config', '${publicRuntimeConfig.GOOGLE_ANALYTICS}');
            `}
            </Script>
          </>
        )}
        <ErrorBoundary>{getLayout(<Component {...pageProps} />)}</ErrorBoundary>
      </AppProviders>
    </ApolloProvider>
  );
}
