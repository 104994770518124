import getConfig from 'next/config';
import { LoggerFactory } from '@cvent/logging/LoggerFactory';

const { publicRuntimeConfig } = getConfig();

export const datadogOptions = {
  applicationId: publicRuntimeConfig.DD_APP_ID,
  clientToken: publicRuntimeConfig.DD_CLIENT_TOKEN,
  service: publicRuntimeConfig.DD_SERVICE,
  env: publicRuntimeConfig.DD_ENV,
  version: publicRuntimeConfig.DD_VERSION
};

/**
 * Initializes application logging.
 * Should be pulled in before starting logging otherwise will the app will fail on logger factory static method.
 * Currently added to top page __app only but in case of shifting to early logins or extended apps
 * needs to be invoked there too
 */
export const initLogging = () => {
  if (typeof window !== 'undefined') {
    LoggerFactory.initConfig({
      loggerOptions: {
        level: publicRuntimeConfig.LOG_LEVEL?.toLowerCase() || 'warn'
      },
      ...(process.env.NODE_ENV === 'production'
        ? { datadogOptions }
        : {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            browserTransport: () => {} // for developement env configure a noop browser transport so that it logs to console instead of trying to send logs to DD
          })
    });
  }
};
