import { NormalizedCacheObject, ApolloCache } from '@apollo/client';
import gql from 'graphql-tag';
import Router from 'next/router';
import { PAGE_IDENTIFIER_404 } from '@pages/404';
import { PAGE_IDENTIFIER_500 } from '@pages/500';
import { GraphQLError } from 'graphql';
import { Logger } from '@cvent/nucleus-logging';

const LOG = new Logger('error-handler');

export enum ErrorCodes {
  NotFound = 'NOT_FOUND',
  UnAuthorized = 'UNAUTHORIZED',
  ServerError = 'INTERNAL_SERVER_ERROR'
}

export const handleGraphqlError = ({ graphQLErrors, cache }: { graphQLErrors: any; cache: any }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }: { extensions: any }) => {
      graphqlErrorHandler({
        cache,
        extensions
      });
    });
  }
};

const graphqlErrorHandler = ({ cache, extensions, expressRes }: { cache: any; extensions: any; expressRes?: any }) => {
  if (extensions?.code === ErrorCodes.NotFound || extensions?.code === ErrorCodes.UnAuthorized) {
    setPageError(cache, ErrorCodes.NotFound);
    expressRes?.status(404);
  } else if (extensions?.code === ErrorCodes.ServerError) {
    setPageError(cache, ErrorCodes.ServerError);
    expressRes?.status(500);
  }
};

export const GET_PAGE_ERROR = gql`
  query GetPageError {
    pageError @client
  }
`;

export const setPageError = (cache: ApolloCache<NormalizedCacheObject>, code: ErrorCodes) => {
  cache.writeQuery({
    query: GET_PAGE_ERROR,
    data: {
      pageError: code
    }
  });
};

export const processApolloErrors = pageErrorData => {
  if (pageErrorData?.pageError === ErrorCodes.NotFound) {
    Router.push(PAGE_IDENTIFIER_404).then();
  }

  if (pageErrorData?.pageError === ErrorCodes.ServerError) {
    Router.push(PAGE_IDENTIFIER_500).then();
  }
};

export const throwNotFoundError = (errorMessage: string): never => {
  LOG.info(errorMessage);
  throw new GraphQLError(errorMessage, null, null, null, null, null, {
    code: ErrorCodes.NotFound
  });
};
