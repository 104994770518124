import { useEffect } from 'react';
import { Logger } from '@cvent/nucleus-logging';
import { pendoInit, PendoTrackingAccount, PendoTrackingVisitor } from '@components/pendo/pendo_init';
import { useQuery } from '@apollo/client';
import { fetchMetadata } from '@operations/auth';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const LOG = new Logger('Pendo');

const application = 'Manage';

export default function Pendo(): JSX.Element {
  const { data } = useQuery(fetchMetadata, {
    onError(err) {
      LOG.error('Failed loading pendo data details', err);
    }
  });

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window?.hasOwnProperty('pendo') !== null &&
      typeof data !== 'undefined' &&
      data?.fetchMetadata !== null
    ) {
      const userMetadata = data?.fetchMetadata?.authorization?.metadata;

      const pendoVistor: PendoTrackingVisitor = {
        environment: publicRuntimeConfig.DD_ENV,
        application,
        testUser: userMetadata?.testUser,
        id: userMetadata?.userId,
        userName: userMetadata?.username,
        userRole: userMetadata?.participantType,
        emailAddress: userMetadata?.emailAddress,
        userAccountStatus: userMetadata?.userStatus
      };

      const pendoAccount: PendoTrackingAccount = {
        accountType: userMetadata?.objectTypeId,
        id: userMetadata?.participantId,
        isSisterPropertyOrganization: userMetadata?.hasSisterHotels,
        isAPIEnabled: userMetadata?.apiUser
      };

      const pendoConfig = {
        visitor: pendoVistor,
        account: pendoAccount
      };
      pendoInit(pendoConfig);
    }
  }, [data]);

  return null;
}
