/* eslint-disable @typescript-eslint/no-empty-function */
// TODO: move this css boilerplate into a next.js plugin
import 'normalize.css'; // css reset
import '@cvent/carina/fonts/fonts.web.css';

import React, { ReactNode } from 'react';
import { ThemeProvider } from '@components/ThemeProvider';
import { Breakpoints } from '@cvent/carina/components/Breakpoints/Breakpoints';
import { GlobalStyles } from './GlobalStyles';
import { ThemeProvider as ThemeProviderV0 } from '@cvent/carina-theme-provider'; // We're still using come CarinaV0 components that have not yet been migrated to CarinaV1, as of 1.35.0, so we need the old ThemeProvider for those components
import { getDefaultTheme } from '@cvent/carina/utils/tokens';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { LocalizationProvider } from 'nucleus-text';
import locales from '../../locales';
import Pendo from '@components/Pendo';

/**
 * Custom app function that allows wrap Nextjs application with theme and localization providers.
 *
 * @param children that should be rendered inside the app
 * @constructor
 */
export default function AppProviders({ children }: { children: ReactNode }): JSX.Element {
  return (
    <ThemeProvider>
      <LocalizationProvider locale="en-US" clientSideLocales={locales} serverSideLocales={locales}>
        <ThemeProviderV0 theme={getDefaultTheme()}>
          <GlobalStyles useGlobalStyles={(): void => {}} />
          <Breakpoints ssrSize="m" key={typeof window === 'undefined' ? 'ssr' : 'csr'}>
            {children}
          </Breakpoints>
        </ThemeProviderV0>
      </LocalizationProvider>
      <Pendo />
    </ThemeProvider>
  );
}
