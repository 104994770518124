import React from 'react';
import { useContext, useMemo } from 'react';
import { Global } from '@emotion/react';
import { ThemeContext } from '@cvent/carina/components/ThemeProvider';
import { GlobalTypography } from '@cvent/carina/components/Typography';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-function-return-type
  useGlobalStyles: (theme: any) => any;
};

// GlobalStyles component; accepts a hook containing an app's additional custom global styles
export function GlobalStyles({ useGlobalStyles }: Props): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme: any = useContext(ThemeContext);
  const _globalStyles = useGlobalStyles(theme);

  const globalStyles = useMemo(
    () => ({
      '*': { boxSizing: 'border-box' },
      ..._globalStyles
    }),
    [_globalStyles]
  );

  return (
    <>
      <GlobalTypography />
      <Global styles={globalStyles} />
    </>
  );
}

export default GlobalStyles;
