// add pendo property to window
declare global {
  interface Window {
    pendo: any;
  }
}

/**
 * Object representing Pendo Visitor.
 */
export interface PendoTrackingVisitor {
  id: string; // Passkey User ID
  environment?: string;
  application: string;
  localId?: string;
  userName?: string; // The logged-in username.
  emailAddress?: string; // The user email address
  userRole?: string; // The logged-in user's associated user type
  userAccountStatus?: string; // The logged-in user's account status
  country?: string;
  testUser?: Boolean;
  userLastLoginUTC?: String;
}

/**
 * Object representing Pendo Account.
 */
export interface PendoTrackingAccount {
  id: string; // The organization's unique participant id.
  isLicenced?: Boolean; // Indicates whether this user belongs to a Passkey-licensed organization
  isSisterPropertyOrganization?: Boolean; // Indicates whether this user's affiliated org is a SpOrg.
  accountName?: String; // The name of the user's parent organization
  accountType?: String; // Indicates the enumerated EVENTPROFILE.OBJECTTYPE type of the user's affiliated company
  // account.
  organizationChainName?: String; // The Organization's Chain name is the name of the top-level holding company that
  // owns or manages a set of corporate brands (specifically, the brand to which the user's organization belongs).
  organizationBrandName?: String; // The Brand name is the brand under which the user's particular property/org
  // is managed and marketed.
  country?: string; // Gets the two letter country code for the country of origin for this account
  stateOrProvince?: string; // Gets the state or province of origin for this account
  isGroupLinkEnabled?: Boolean; // Indicates whether or not user's organization is enabled for GroupLink integration (Currently Hotels only)
  isAPIEnabled?: Boolean; // Indicates whether or not user's hotel/org is enabled for API integration.
  isCommerceEnabled?: Boolean; // Indicates whether the user's organization owns an active merchant account.
}

/**
 * PENDO Init function to initialize pendo with visitor and account information.
 */
export function pendoInit(metaData: { visitor: PendoTrackingVisitor; account: PendoTrackingAccount }): void {
  const { visitor, account } = metaData;

  if (typeof window !== 'undefined' && window.pendo) {
    window.pendo.initialize({
      visitor,
      account
    });
  }
}
