import React, { useRef } from 'react';
import { getIllustrationFromType } from '@cvent/carina/components/Templates/getIllustrationFromType';
import { IllustrationContent } from '@cvent/carina/components/Templates/IllustrationContent';
import { IllustrationHeader } from '@cvent/carina/components/Templates/IllustrationHeader';
import { IllustrationNotice } from '@cvent/carina/components/Templates/IllustrationNotice';
import { IllustrationErrorTypes as ErrorTypes } from '@cvent/carina/components/Templates/IllustrationTypes';
import { ContentArea } from '@cvent/carina/components/Templates/ContentArea';
import { TemplateActions as Actions } from '@cvent/carina/components/Templates/TemplateActions';
import { Body } from '@cvent/carina/components/Templates/Body';
import { Main } from '@cvent/carina/components/Templates/Main';
import { Page } from '@cvent/carina/components/Templates/Page';
import { NextPage } from 'next/types';
import Router from 'next/router';

export const PAGE_IDENTIFIER_404 = '/404';

const actions = (returnUrl: React.MutableRefObject<string>) => [
  {
    value: 'Go Back',
    onClick: (): void => {
      if (returnUrl.current) {
        Router.push(returnUrl.current);
      } else {
        Router.back();
      }
    }
  }
];

export const NotFound: NextPage = () => {
  const returnUrl: React.MutableRefObject<string> = useRef(null);
  return (
    <Page>
      <Main>
        <Body>
          <ContentArea>
            <IllustrationNotice
              title="Oops, something went wrong"
              customIllustration=""
              illustration={getIllustrationFromType(ErrorTypes.ERROR_404)}
            >
              <IllustrationHeader>Oops, something went wrong</IllustrationHeader>
              <IllustrationContent>We couldn't find the page you were looking for.</IllustrationContent>
              <Actions actions={actions(returnUrl)} position="center" />
            </IllustrationNotice>
          </ContentArea>
        </Body>
      </Main>
    </Page>
  );
};

export default NotFound;
