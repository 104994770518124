import React, { Component, ErrorInfo } from 'react';
import Router from 'next/router';
import { Logger } from '@cvent/nucleus-logging/lib';
import { PAGE_IDENTIFIER_500 } from '@pages/500';

const LOG = new Logger('ErrorBoundary');

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    LOG.error('[ErrorBoundary error]: ', error, ', Error message: ', errorInfo);
    this.setState({ hasError: true });
    Router.push(PAGE_IDENTIFIER_500);
  }

  render() {
    if (this.state.hasError) {
      return <></>; // render nothing here, as we're redirecting to 500
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
