import React, { useRef } from 'react';
import HeroErrorShrug from '@cvent/carina/components/Illustrations/HeroErrorShrug';
import { IllustrationContent } from '@cvent/carina/components/Templates/IllustrationContent';
import { IllustrationHeader } from '@cvent/carina/components/Templates/IllustrationHeader';
import { IllustrationNotice } from '@cvent/carina/components/Templates/IllustrationNotice';
import { ContentArea } from '@cvent/carina/components/Templates/ContentArea';
import { TemplateActions as Actions } from '@cvent/carina/components/Templates/TemplateActions';
import { Body } from '@cvent/carina/components/Templates/Body';
import { Main } from '@cvent/carina/components/Templates/Main';
import { Page } from '@cvent/carina/components/Templates/Page';
import Router from 'next/router';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useTranslate } from 'nucleus-text';
import { NextPage } from 'next/types';

export const PAGE_IDENTIFIER_500 = '/500';

export const InternalServerError: NextPage = () => {
  const { translate } = useTranslate();
  const actions = (returnUrl: React.MutableRefObject<string>) => [
    {
      value: translate('Lighthouse_Pages_Error_BackButton'),
      onClick: (): void => {
        if (returnUrl.current) {
          Router.push(returnUrl.current);
        } else {
          Router.back();
        }
      }
    }
  ];
  const returnUrl: React.MutableRefObject<string> = useRef(null);
  return (
    <Page>
      <Main>
        <Body>
          <ContentArea>
            <IllustrationNotice
              testID="500-error-notice"
              title={translate('Lighthouse_Pages_Error_Illustration_Title_Content_resx')}
              illustration={HeroErrorShrug}
            >
              <IllustrationHeader>
                {translate('Lighthouse_Pages_Error_Illustration_Title_Content_resx')}
              </IllustrationHeader>
              <IllustrationContent>
                {translate('Lighthouse_Pages_Error_Illustration_Content_TryAgain_resx')}
              </IllustrationContent>
              <Actions actions={actions(returnUrl)} position="center" />
            </IllustrationNotice>
          </ContentArea>
        </Body>
      </Main>
    </Page>
  );
};

export default InternalServerError;
